import React, { useRef,useEffect, useState } from 'react';
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo";
import Layout from "../components/layout";
import Header from "../components/headerHomepage";
import OriginHeroes from '../video/oh2_noaudio.mp4';


const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
};

// markup
const IndexPage = () => {

  const videoParentRef = useRef();
  const [shouldUseImage, setShouldUseImage] = useState(false);
  useEffect(() => {
    // check if user agent is safari and we have the ref to the container <div />
    if (isSafari() && videoParentRef.current) {
      // obtain reference to the video element
      const player = videoParentRef.current.children[0];

      // if the reference to video player has been obtained
      if (player) {
        // set the video attributes using javascript as per the
        // webkit Policy
        player.controls = false;
        player.playsinline = true;
        player.muted = true;
        player.setAttribute("muted", ""); // leave no stones unturned :)
        player.autoplay = true;

        // Let's wait for an event loop tick and be async.
        setTimeout(() => {
          // player.play() might return a promise but it's not guaranteed crossbrowser.
          const promise = player.play();
          // let's play safe to ensure that if we do have a promise
          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                // if promise fails, hide the video and fallback to <img> tag
                videoParentRef.current.style.display = "none";
                setShouldUseImage(true);
              });
          }
        }, 0);
      }
    }
  }, []);

  const elementRef = useRef(null);
  const [first, setfirst] = useState(0);
  const [heightyo, setheightyo] = useState(0);

  useEffect(() => {
    const zoom = elementRef.current?.clientHeight;
    const he = window.innerHeight;
    setfirst(zoom);
    setheightyo(he);
  }, []);


  return (
    <Layout classmain="oh container">
      <Seo
        title='Welcome to Etheria'
        metaDesciption='A limited NFT collection of unique and diverse utility-powered Heroes. Each Hero doubles as your gateway to exploring the fantastical world of Etheria.'
      />
        <Header/>
        <div className='homepage'>
          <div className='homepage-video'>
            { shouldUseImage ? 
              <img src={OriginHeroes} alt="Original Heroes"/>
            : 
              <div
                ref={videoParentRef}
                dangerouslySetInnerHTML={{
                  __html: `
                  <video
                    loop
                    muted
                    autoplay
                    playsinline
                    preload="metadata"
                    id="ohVideo"
                    poster="/images/back-home.jpg"
                    class="hp__container-video"
                  >
                  <source src="${OriginHeroes}" type="video/mp4" />
                  </video>`
                }}
              />
            }
          </div>
          <div className='homepage-mobile' style={{height: heightyo - (first - 180) + 'px'}}>
            <StaticImage
                alt='Origin Heroes'
                className="homepage-mobile__back"
                src='../images/back-home.jpg'
                width={1920}
                quality='100'
            />
            <StaticImage
                alt='Origin Heroes'
                objectFit='contain'
                className="homepage-mobile__ch"
                src='../images/ch-home.png'
            />
          </div>
        </div>
      <div className="footer footer--homepage" ref={elementRef}>
        <footer className="footer-container container">
          <div className="footer__row">
              <p className="footer__date">© {new Date().getFullYear()} Tastypill. All rights reserved.</p>
              <div className="footer__right">
                <h2 className="footer__title">A limited NFT collection of unique and diverse utility-powered Heroes.<br/>Each Hero doubles as your gateway to exploring the fantastical world of Etheria.</h2>
                <div className="footer__button">
                  <a href="/etheria" className="enter">
                      <img className="enter-img" src="/images/button.png" alt=""/>
                      <span id="txt">Enter</span>
                      <img src="/images/arrow.svg" id="arrow" alt=""/>
                      <div className="go"></div>
                  </a>
                </div>
              </div>
          </div>
        </footer>
      </div>
    </Layout>
  )
}

export default IndexPage
