import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const HeaderHomepage = () => {

  return (
    <header className='header header--homepage'>
      <div className='header__container container'>
        <Link
          className="header__leftside navbar-brand"
          to="/"
        >
          <StaticImage
              alt='Origin Heroes'
              objectFit='contain'
              className='logoback'
              src='../images/logo-back.png'
          />
          <StaticImage
              alt='Origin Heroes'
              objectFit='contain'
              className="header__leftside-logo"
              src='../images/logo.png'
          />
        </Link>
        <div className="header__rightside">
          <ul className='soc__icons soc__icons--header'>
              <li className='soc__icons-title'>
              <a href='https://twitter.com/OriginHeroes'>
                  <img
                      alt='Twitter'
                      className='soc__icons--image'
                      src='../images/tw.svg'
                  />
              </a>
              </li>
              <li className='soc__icons-title'>
              <a href='https://discord.gg/KRqbAKdcXY'>
                  <img
                      alt='Discord'
                      className='soc__icons--image'
                      src='../images/discord.svg'
                  />
              </a>
              </li>
              <li className='soc__icons-title'>
              <a href='https://originheroes.gitbook.io/origin-heroes/'>
                  <img
                      alt='LITEPAPER'
                      className='soc__icons--image'
                      src='../images/lp.svg'
                  />
              </a>
              </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default HeaderHomepage;
